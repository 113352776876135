<template>
  <div class="page">
    <div class="welcome-div">{{ $t('welcome') }}</div>
    <div class="top">
      <div class="apply-tips">
<!--        <span class="top-tips">{{ $t('ssd_12') }}</span>-->
        <span class="top-tips-2">{{ $t('account_balance') }}</span>
<!--        <div class="split-line"></div>-->
        <span class="amount">{{ $t('currency') }}<span>{{ `${minAmount}-${maxAmount}` }}</span></span>
        <div class="tips-item-box">
          <span class="tips-item">
            {{ $t('ssd_18') }}: 0.5%
          </span>
          <span class="tips-item">
            {{ $t('ssd_19') }}: 30ヶ{{ $t('month') }}
          </span>
        </div>
      </div>
    </div>
    <image-list/>
    <div class="apply-box">
      <van-button @click.native="checkUser" class="default-background" block>
        {{ $t(btnDesc) }}
      </van-button>
      <van-checkbox class="policy-div" v-model="checked" icon-size="16px">
        <span class="read">{{$t('read_and_agree')}}
          <a class="policy" @click="showPolicy = true" @click.stop="(event)=>event.stopPropagation()">
            ({{$t('policy_financial_department')}})</a>
        </span>
      </van-checkbox>
    </div>
    <div class="icon-div">
      <template v-for="(item, index) of iconList">
        <div class="icon-content" @click="item.click" v-if="item.show" :key="index" :class="item.class">
          <div class="left-div">
            <img class="icon" :src="item.icon"/>
            <span class="icon-title">{{ item.title }}</span>
          </div>
<!--          <i class="el-icon-arrow-right"></i>-->
        </div>
      </template>
    </div>
    <bottom-bar current-name="Home"></bottom-bar>
    <signature-dialog :next="goNext" :visible="showSign"/>
    <div class="policy-content-box" v-if="showPolicy" @click="showPolicy=false">
      <div class="policy-content" @click.stop="(event)=>event.stopPropagation()">
        <p>アイフルグループはお客様の期待を超えるサービス・商品を提供し、国内外で信頼され、必要とされるグローバル金融グループを目指しております。</p>
        <p>日本国内でのローン事業においては、アイフルが、消費者向け無担保や事業者向けのローン事業を、AGビジネスサポートでは事業者向けローンを取り扱っております。クレジットカード事業においては、Mastercard、Visa、JCBの国際ブランドのライセンスを発行するライフカードが取り扱い、信用保証事業においては、アイフル、ライフの双方のブランドで各金融機関向けの保証を行っております。</p>
        <p>また、海外事業においてはA＆A（アイラ＆アイフル）がタイで消費者向け無担保ローン事業を、レクサファイナンスがインドネシアで中古車オートローン事業を展開しております。</p>
        <p>このほかにおいては、プリペイドカード事業（ライフカード）、ファクタリング（AGビジネスサポート）、サービサー業・企業再生事業（AG債権回収）、ベンチャーキャピタル（AGキャピタル）、家賃保証事業（あんしん保証）、自社割賦・個品割賦事業・後払い決済事業（AGペイメントサービス）を行っております。</p>
      </div>
    </div>
    <!--    <div class="bottom-img">-->
    <!--      <img :src="bottomImg">-->
    <!--    </div>-->
    <van-overlay :show="showReject" class-name="reject-box">
      <div class="wrapper" @click.stop>
        <span class="error-msg">Since there is no contact information for you
The system has declined, please contact customer service to reactivate</span>
        <van-button type="info" block @click="store.contactUs">
          {{ $t('click_to_contact_service') }}
        </van-button>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import interestRateImg from '@/assets/interest_rate.png';
import calendarImg from '@/assets/calendar.png';
import getLoanImg from '@/assets/get-loan.png';
import loanRecordImg from '@/assets/loan-record.png';
import helpImg from '@/assets/help.png';
import transactionHistoryImg from '@/assets/transaction-history.png';
import websiteImg from '@/assets/website.png';
import cooperationImg from '@/assets/cooperation.png';
import rateImg from '@/assets/rate.png';
import bottomImg from '@/assets/bottom.png';
import walletImg from '@/assets/wallet.png';
import store from '@/store';
import {appService, orderService} from "@/services";

export default {
  name: "HomePage",
  data() {
    return {
      interestRateImg,
      calendarImg,
      getLoanImg,
      loanRecordImg,
      helpImg,
      transactionHistoryImg,
      websiteImg,
      cooperationImg,
      rateImg,
      bottomImg,
      walletImg,
      store,
      checked: true,
      statusSource: null,
      info: null,
      appName: process.env.DEPLOY_CONFIGS.title,
      unAuth: false,
      loading: false,
      condition: false,
      showSign: false,
      loanProgress: null,
      btnDesc: 'ssd_1',
      showPolicy: false,
      website: null,
      showReject: false,
      iconList: [
        {
          icon: loanRecordImg,
          click: () => this.goPage('/repayDetail'),
          title: this.$t('repay_title'),
          show: true
        },
        {
          icon: helpImg,
          click: store.contactUs,
          title: this.$t('help'),
          show: store.showContact
        },
        {
          icon: transactionHistoryImg,
          click: () => this.goPage('/transactionHistory'),
          title: this.$t('transaction_history'),
          show: true,
        },
        {
          icon: websiteImg,
          click: () => this.showPolicy = true,
          title: this.$t('mine_about_us'),
          show: true
        },
        {
          icon: cooperationImg,
          click: () => this.goPage('/partner'),
          title: this.$t('cooperation'),
          show: true
        },
        {
          icon: rateImg,
          click: () => this.goPage('/contract'),
          title: this.$t('contract'),
          show: true
        },
        {
          icon: walletImg,
          click: this.checkUser,
          title: this.$t('wallet'),
          show: true
        },
      ],
      minAmount: 80000,
      maxAmount: 3000000,
      amountList: [],
    }
  },
  async activated() {
    await this.getAmountRange();
    this.info = await store.getUser(true);
    this.statusSource = this.info.userQualificationView;
    if ((this.statusSource.bankCard != 1 && this.statusSource.bankCard != 2)
        || this.statusSource.basicInformation != 1 && this.statusSource.basicInformation != 2
        || (this.statusSource.identity != 1 && this.statusSource.identity != 2)) {
      this.unAuth = true;
    } else {
      this.unAuth = false;
    }
    this.loanProgress = await orderService.getLoanProgress();
    this.showReject = this.loanProgress.status === 5
    if (this.unAuth) {
      this.btnDesc = 'ssd_1';
    } else if (!this.loanProgress || !this.loanProgress.status) {
      this.btnDesc = 'common_apply';
    } else {
      this.btnDesc = 'wallet';
    }
    await this.getConfig();
    this.showSign = false;
  },
  methods: {
    async getConfig() {
      this.website = await store.getConfig("website")
    },
    async getAmountRange() {
      let periodConfigs = await appService.getPeriodConfig({appId: process.env.DEPLOY_CONFIGS.appId});
      periodConfigs.rateConfigs.forEach(r => {
        this.amountList = this.amountList.concat(r.amounts)
      });
      this.amountList.sort((a, b) => a - b)
      this.minAmount = this.amountList[0];
      this.maxAmount = this.amountList[this.amountList.length-1];
    },
    checkUser() {
      if (!this.info.signatureImg) {
        this.showSign = true;
      } else {
        this.goNext();
      }
    },
    goNext() {
      if (this.unAuth) {
        this.$router.push('/baseInfo');
      } else if (!this.loanProgress || !this.loanProgress.status) {
        store.getAppList().then((appList) => {
          this.$router.push("/apply/" + appList[0].id);
        });
      } else {
        this.$router.push("/detail/" + this.loanProgress.orderId);
      }
    },
    goPage(path) {
      this.$router.push(path);
    },
    goWebsite() {
      window.open(this.website, "_blank");
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  padding-bottom: 57px;
  min-height: calc(100% - 57px);
  background: #F1F1F1;

  .welcome-div {
    font-size: 14px;
    font-weight: 700;
    color: #177144;
    padding: 20px 20px 10px;
    text-align: center;
  }

  .top {
    background: #FE9E14;
    margin: 10px 15px 15px;
    border-radius: 5px;
    .top-tips {
      display: block;
      color: #177144;
      font-size: 18px;
      padding-top: 20px;
      font-weight: 700;
    }
    .top-tips-2 {
      display: block;
      margin-top: 10px;
      font-size: 16px;
      color: #333333;
      font-weight: 700;
    }

    .apply-tips {
      margin-bottom: 15px;
      color: #333333;
      text-align: left;
      border-radius: 10px;
      padding: 20px;

      .amount {
        color: #ffffff;
        margin-top: 15px;
        display: block;
        font-size: 26px;
        font-weight: 700;
        text-align: center;
         span {
           color: #ffffff;
           padding: 5px;
           border-radius: 5px;
         }
      }

      .split-line {
        margin: 10px 0;
        height: 1px;
        border-bottom: 5px solid #FFFFFF;
      }

      .apply-title {
        margin: 20px 0;
      }

      .tips-item-box {
        display: flex;
        margin-top: 15px;
        font-size: 12px;
        padding-bottom: 10px;
        justify-content: left;

        .tips-item {
          color: #333333;

          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .top2 {
    background-color: #FF4267;
    height: 8px;
    margin: 0 40px;
    border-radius: 0 0 30px 30px;
  }

  .top3 {
    background-color: #5655B9;
    height: 8px;
    margin: 0 55px;
    border-radius: 0 0 30px 30px;
  }

  .apply-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px 15px 0;
    color: #ffffff;
    .van-button {
      height: 40px;
      border-radius: 5px;
      border: none;
      background: @button-color;
      font-weight: 700;
      color: #ffffff;
      font-size: 14px;
    }
  }
  .icon-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 15px 30px;
    justify-content: space-between;
    border-radius: 5px;
    background-color: @base-color;

    .icon-content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      color: #666666;
      justify-content: center;
      width: 33%;
      aspect-ratio: 1 / 1;

      &:nth-child(3n){
        &::after {
         display: none;
        }
        &::before {
         left: 0;
        }
      }
      &:nth-child(3n-1){
        &::before {
          width: 100%;
        }
      }
      &:nth-child(n+4){
        &::after {
          top: 0;
        }
        &::before {
          height: 0;
        }
      }
      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 70%;
        height: 1px;
        background: #64A0CB;
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        height: 70%;
        width: 1px;
        background: #64A0CB;
      }

      .left-div {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      .icon {
        width: 38px;
        margin-bottom: 10px;
      }

      .icon-title {
        font-size: 12px;
        text-align: center;
      }
    }
    .icon-content.history {
      padding:  10px;
      .icon {
        width: 28px;
        margin-right: 15px;
        margin-left: -5px;
      }
    }
  }

  .policy-div {
    margin-top: 10px;
    font-size: 12px;
    color: #222222D9;
    padding-bottom: 15px;
    font-weight: 500;

    /deep/.van-checkbox__icon--checked .van-icon {
      color: #fff;
      background-color: #03BD5F;
      border-color: #03BD5F;
    }

    .select-div {
      height: 20px;
      width: 20px;
    }

    .read {
      color: #222222D9;
    }

    .policy {
      color: #222222D9;
    }
  }

  .policy-content-box {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .policy-content {
    padding: 10px;
    background-color: #ffffff;
    margin: auto;
    max-height: calc(100% - 120px);
    width: 80%;
    overflow: scroll;
  }

  .reject-box {
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
      background: #FFFFFF;
      margin: 50px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
      border-radius: 6px;
      font-size: 14px;

      .error-msg {
        color: #535D66;
      }

      /deep/ .van-button {
        height: 35px;
        font-size: 14px;
        background: #FF3D00;
        border-radius: 6px;
        margin-top: 15px;
      }
    }
  }

  .bottom-img {
    margin-top: 20px;
    width: 100%;

    img {
      width: 100%;
    }
  }
}

</style>
<style>
html, body {
  margin: 0;
  padding: 0;
}
</style>