<template>
  <div class="page">
    <div class="top-div">
      <k-language></k-language>
      <div class="login-header">
        <div class="title">
          <span class="welcome">{{ isRegister ? $t('common_register') : $t('login_title') }}</span>
          <img :src="moneyBagImg"/>
        </div>
      </div>
      <div class="form-div">
        <div class="form-item">
          <span class="input-desc">{{ $t('common_phone') }}</span>
          <van-field type="tel" v-model="mobile" class="input" :maxlength="11"
                     :formatter="formatterNumber"  :left-icon="phoneIcon"
                   :class="{'register-input': isRegister}"  :placeholder="$t('common_phone')"/>
        </div>
        <div class="form-item">
          <span class="input-desc">{{ $t('common_password') }}</span>
          <van-field class="input" :type="showPassword? 'text' : 'password'" v-model="password"
                     :left-icon="lockIcon" :placeholder="$t('common_password')">
            <template #button>
              <span @click="() => showPassword = !showPassword">
                 <van-icon name="closed-eye" v-if="showPassword"/>
                 <van-icon name="eye-o" v-else/>
              </span>
            </template>
          </van-field>
        </div>
        <div class="form-item" v-if="isRegister">
          <span class="input-desc">{{ $t('confirm_password') }}</span>
          <van-field class="input" :type="showConfirmPassword? 'text' : 'password'" v-model="passwordConfirm"
                     :left-icon="lockIcon" :placeholder="$t('confirm_password')">
            <template #button>
              <span @click="() => showConfirmPassword = !showConfirmPassword">
                 <van-icon name="closed-eye" v-if="showConfirmPassword"/>
                 <van-icon name="eye-o" v-else/>
              </span>
            </template>
          </van-field>
        </div>
        <div class="form-item" v-if="isRegister">
          <span class="input-desc">{{ $t('invite_code') }}</span>
          <van-field class="input" type="text" v-model="telemarketingCode" :placeholder="$t('invite_code')" :left-icon="inviteIcon">
            <!--          <template #button>-->
            <!--            <van-button class="otp-btn" :loading="loadingCode" :disabled="!canGetOtp" :class="{'disabled': !canGetOtp}"-->
            <!--                        size=small @click="getOtp">-->
            <!--              {{ canGetOtp ? $t("common_captcha") : `${countDownTime}s` }}-->
            <!--            </van-button>-->
            <!--          </template>-->
          </van-field>
        </div>
        <image-list v-else style="margin-top: 30px"/>
      </div>
    </div>
    <div class="bottom">
        <div class="btn-div">
          <van-button type="warning" :loading="loading" :disabled="!canPost" :class="{'disabled': !canPost}" class="btn"
                      @click.native="login" block>
            {{ isRegister ? $t('common_register') : $t('login_title') }}
          </van-button>
        </div>
        <div class="tips-btn-div">
          <div class="btn-split-div">
            <div class="split-line"></div>
            <span class="left-tips">{{ isRegister ? $t('registered') : $t('not_registered') }}?&nbsp;</span>
            <div class="split-line"></div>
          </div>
          <van-button class="tips-btn" @click="changeType">{{ isRegister ? $t('login_title') : $t('common_register') }} </van-button>
        </div>
      </div>
  </div>
</template>
<script>
import moneyBagImg from '../assets/money-bag.png';
import phoneIcon from '../assets/phone-icon.png';
import lockIcon from '../assets/lock-icon.png';
import inviteIcon from '../assets/invite-icon.png';
import store from '@/store';
import axios from '@/common/axios';
import {validateMobile} from '@/common/validate';

export default {
  name: "IndexPage",
  data() {
    return {
      moneyBagImg,
      phoneIcon,
      lockIcon,
      inviteIcon,
      countDownTime: 60,
      showPassword: false,
      showConfirmPassword: false,
      mobile: '',
      canGetOtp: true,
      placePhone: this.$t('common_phone_input_placeholder'),
      placePassword: this.$t('common_password_input_placeholder'),
      placePasswordConfirm: this.$t('common_password_confirm_placeholder'),
      show: false,
      loading: false,
      loadingCode: false,
      password: null,
      passwordConfirm: null,
      isRegister: false,
      appName: process.env.DEPLOY_CONFIGS.title,
      telemarketingCode: ''
    }
  },
  async activated() {
    this.isRegister = this.$route.path.endsWith('/register');
  },
  computed: {
    canPost() {
      if (this.isRegister) {
        return this.mobile && this.mobile.toString().length >= 9 && this.password && this.passwordConfirm && this.telemarketingCode;
      } else {
        return this.mobile && this.password;
      }
    },
    title() {
      if (this.isRegister) {
        return this.$t('common_register')
      } else {
        return this.$t('login_title')
      }
    }
  },
  methods: {
    formatterNumber(value) {
      if (this.isRegister) {
        return value && value.replace(/\D/g, '');
      }
      return value;
    },
    changeType() {
      if (this.isRegister) {
        this.$router.push('/login');
      } else {
        this.$router.push('/register');
      }
      this.isRegister = !this.isRegister;
    },
    async getOtp() {
      if (!validateMobile(this.mobile)) {
        this.$toast(this.$t('phone_input'));
        return;
      }
      if (this.isRegister) {
        if (this.password !== this.passwordConfirm) {
          this.$toast(this.$t('common_password_not_match'));
          return;
        }
      }
      let params = {
        mobile: this.mobile,
        appId: process.env.DEPLOY_CONFIGS.appId,
        companyId: process.env.DEPLOY_CONFIGS.companyId,
      }
      this.loadingCode = true;
      axios.post(`/sms/${this.isRegister ? 'register' : 'login'}`, params)
          .then(() => {
            this.loadingCode = false
            this.codeCountDown();
          })
          .catch(() => this.loadingCode = false);
    },
    codeCountDown() {
      this.canGetOtp = false;
      this.countDownTime = 60
      let time = setInterval(() => {
        this.countDownTime--
        if (this.countDownTime < 1) {
          this.canGetOtp = true
          this.countDownTime = 60
          clearInterval(time)
        }
      }, 1000);
    },
    async login() {
      if (this.isRegister) {
        if (!validateMobile(this.mobile)) {
          this.$toast(this.$t('phone_input'));
          return;
        }
        if (this.password !== this.passwordConfirm) {
          this.$toast(this.$t('common_password_not_match'));
          return;
        }
      }
      let params = {
        mobile: this.mobile,
        // captcha: this.captcha,
        installationSource: localStorage.getItem('channel'),
        platform: "h5",
        password: this.password,
        companyId: process.env.DEPLOY_CONFIGS.companyId,
        appId: process.env.DEPLOY_CONFIGS.appId,
        telemarketingCode: this.telemarketingCode
      }
      if (this.canPost) {
        this.loading = true;
        axios.post(`/user/${this.isRegister ? 'register' : 'login'}`, params)
            .then((login) => {
              this.loading = false;
              store.commit('mobile', this.mobile);
              store.updateUser(null)
              store.setToken(login.token);
              this.$router.push('/');
            })
            .catch(() => this.loading = false);
      }
    },
  }
}
</script>
<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  min-height: 100%;
  background: @base-color;

  .top-div {
    position: relative;
    /deep/.el-select {
      position: absolute;
      right: 10px;
      top: 10px;
      .el-input__inner {
        height: 30px;
        border: none;
        background-color: #8ff0a8;
      }
    }
  }

  .login-header {
    padding-top: 30px;
    background: #6FADFF;
    .title {
      color: #002596;
      font-weight: 700;
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .welcome {
        margin-left: 30px;
      }
    }
    img {
      display: block;
      width: 35%;
    }
  }

  .form-div {
    padding: 20px 0;
    background: @base-color;

    .title {
      color: #222222;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 25px;

      .welcome {
        font-size: 28px;
        font-weight: 700;
        display: block;
      }
    }

    .welcome-div {
      padding: 0 55px 0;

      img {
        width: 100%;
      }
    }

    .mobile-div.form-item {
      display: flex;
      background-color: #ffffff;
      margin: 15px;
      padding: 0;

      .prefix {
        font-size: 14px;
        color: #333333;
        padding-left: 10px;
      }

      .input.register-input {
        /deep/ input {
        }
      }
    }

    .form-item {
      padding: 5px 15px 0;
      text-align: left;
      align-items: center;
      margin-bottom: 10px;

      .input-div {
        position: relative;
      }

      /deep/ .otp-btn {
        background-color: @button-color;
        border-radius: 5px;
        color: #FFFFFF;
      }

      /deep/ .van-field__button {
        padding-right: 10px;
      }

      .input-desc {
        font-size: 12px;
        color: #222222;
        display: block;
        margin-bottom: 10px;
        font-weight: 700;
      }

      .input {
        padding: 0;
        border-radius: 5px;
        /deep/ .van-field__left-icon {
          padding-left: 8px;
          .van-icon {
            display: flex;
            height: 100%;
            align-items: center;
            .van-icon__image {
              width: 12px;
            }
          }
        }
        /deep/ .van-cell__value {
          border: none;
          background: #ffffff;
        }

        /deep/ input {
          padding-left: 5px;
          background: #ffffff;
          display: block;
          width: calc(100% - 10px);
          outline: 0;
          height: 40px;
          color: #222222;
          font-size: 14px;
        }

        /deep/ input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          font-size: 12px;
          color: #CCCCCC;
        }

        /deep/ input:-ms-input-placeholder { /* IE 10+ */
          font-size: 12px;
          color: #CCCCCC;
        }

        /deep/ input:-moz-placeholder { /* Firefox 18- */
          font-size: 12px;
          color: #CCCCCC;
          opacity: 1;
        }

        /deep/ input::-moz-placeholder { /* Firefox 19+ */
          font-size: 12px;
          color: #CCCCCC;
          opacity: 1;
        }

        /deep/ input:-webkit-autofill,
        /deep/ input:-webkit-autofill:hover,
        /deep/ input:-webkit-autofill:focus,
        /deep/ input:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    background: @base-color;
    padding-bottom: 30px;
  }

  .btn-div {
    margin: 0 15px 10px;

    /deep/ .btn {
      background: @button-color;
      color: #ffffff;
      border-radius: 5px;
      border: none;
      height: 40px;
      font-size: 16px;
      font-weight: 700;

      &.disabled {
        opacity: 0.5;
      }
    }
  }

  .tips-btn-div {
    margin: 10px 15px;

    text-align: center;
    font-size: 14px;
    color: #FFFFFF;

    .btn-split-div {
      color: #999999;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      justify-content: space-between;
      font-size: 12px;
      .split-line {
        border-top: 1px dashed #999999;
        height: 1px;
        margin: 0 15px;
        flex: 1;
      }
    }
    .left-tips {
      display: block;
    }

    .tips-btn {
      border: none;
      border-radius: 5px;
      padding: 0 25px 0;
      width: 100%;
      background-color: #389705;
      color: #ffffff;
      font-size: 16px;
      height: 40px;
    }
  }
}
</style>
<style>
html, body {
  margin: 0;
  padding: 0;
}
</style>