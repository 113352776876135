import i18n from '@/i18n';

export default {
  checkout() {
    const language = localStorage.getItem('language');
    i18n.locale = language || process.env.DEPLOY_CONFIGS.lang;
  },
  switch(language) {
    localStorage.setItem('language', language);
    i18n.locale = language;
  },
};
