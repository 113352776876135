<template>
  <div class="page">
    <header-bar :title="$t('transaction_history')" background-color="#E7F5FE"/>
    <template v-if="order && order.status == 3">
      <div class="order-item">
        <div class="order-detail">
          <span class="desc">{{ $t('common_order_id') }}:</span>
          <span class="vale">{{ order.orderId }}</span>
        </div>
        <div class="order-detail">
          <span class="desc">{{ $t('common_apply_datetime') }}:</span>
          <span class="vale">{{ order.applyTime }}</span>
        </div>
        <div class="order-detail">
          <span class="desc">{{ $t('withdraw_amount') }}:</span>
          <span class="vale">{{ order.lastWithdrawAmount }}</span>
        </div>
        <div class="order-detail">
          <span class="desc">{{ $t('status') }}:</span>
          <span class="vale" :class="[statusDesc ? 'red': 'green']">{{ statusDesc || 'Pending' }}</span>
        </div>
      </div>
      <image-list/>
    </template>
    <van-empty description="No data" v-else/>
  </div>
</template>
<script>

import {orderService} from "@/services";

export default {
  name: "TransactionHistory",
  data() {
    return {
      order: {},
      statusDesc: null,
    }
  },
  activated() {
    this.getOrderList();
    this.getLastWithdrawError();
  },
  methods: {
    async getOrderList() {
      let orderList = await orderService.getOrderList();
      this.order = orderList && orderList.length && orderList[0]
    },
    async getLastWithdrawError() {
      this.statusDesc = await orderService.getLastWithdrawError();
    },
  }
}
</script>
<style lang="less" scoped>
.page {
  background: url("../../assets/history-bottom.png") no-repeat;
  background-position: 0 110%;
  background-size: contain;
  background-color: #E7F5FE;
  min-height: calc(100% - 44px);
  padding-top: 44px;

  .order-item {
    margin: 20px 0;
    background-color: #BDDAEC;
    padding: 10px 15px;
    border: 1px solid #64A0CB;
    border-radius: 6px;

    .order-detail {
      margin: 15px 0;
      display: flex;
      justify-content: space-between;
      font-size: 16px;

      .desc {
        color: #222222;
      }

      .value {
        color: #333333;
      }

      .red {
        color: #F56C6C !important;
      }
    }
  }

  .green {
    color: green !important;
  }
}
</style>
