<template>
  <div class="base-info">
    <header-bar :title="$t('base_info_title')" background-color="#0158C9" color="#ffffff"/>
<!--    <div class="header-title">-->
<!--      <span>{{ $t('base_info_title') }}</span>-->
<!--    </div>-->
    <div class="page">
      <div class="img-list">
        <div class="identity-img-upload" v-for="(item, index) in identityList"
             :key="index">
          <div class="desc">
            <span class="index"> {{ index + 1 }}</span>
            <span class="img-desc">
          {{ $t(item.desc) }}
          </span>
          </div>
          <el-upload
              :disabled="!needUploadIdentity"
              class="uploader"
              accept=".jpg,.png,.jpeg"
              action="#"
              :http-request="(data)=> uploadImg(data, item )"
              :show-file-list="false"
              :before-upload="beforeUpload">
            <img v-if="item.url" :src="item.url" class="identity-img">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <el-form ref="userForm" :model="modifiedUser" :rules="formRules">
        <div class="user-info">
          <div class="info-item">
            <span class="item-desc">{{ $t('base_info_name') }}:</span>
            <el-input class="item-value" v-model="modifiedUser.realName"
                      :disabled="!!user.realName && !needUploadIdentity"
                      :class="{'readonly': !!user.realName && !needUploadIdentity}"
                      :placeholder="$t('base_info_name_input_placeholder')"/>
          </div>
          <div class="info-item">
            <span class="item-desc">{{ $t('base_info_id_number') }}:</span>
            <el-input class="item-value" v-model="modifiedUser.identity"
                      @input="(value) => modifiedUser.identity = value.replace(/[^\d]/g,'')"
                      :disabled="!!user.identity && !needUploadIdentity"
                      :class="{'readonly': !!user.identity && !needUploadIdentity }"
                      :placeholder="$t('base_info_id_number_input_placeholder')"/>
          </div>
          <div class="radio-div">
            <span class="item-desc"> {{ $t('base_info_gender') }}:</span>
            <van-radio-group v-model="modifiedUser.sex" :disabled="!!user.sex && !needUploadIdentity"
                             class="item-value radio"
                             direction="horizontal">
              <van-radio :name="1">{{ $t('base_info_gender_male') }}</van-radio>
              <van-radio :name="2">{{ $t('base_info_gender_female') }}</van-radio>
            </van-radio-group>
          </div>
          <div class="info-item">
            <span class="item-desc">{{ $t('base_info_birthday') }}:</span>
            <div class="select-div"
                 :class="{'placeholder': !modifiedUser.birthday, 'readonly': !!user.birthday && !needUploadIdentity}">
            <span class="item-value" @click="showBirthdaySelect=!user.birthday || needUploadIdentity">
              {{ modifiedUser.birthday || $t('base_info_birthday_select_placeholder') }}
            </span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="info-item">
            <span class="item-desc"> {{ $t('base_info_marriage') }}:</span>
            <div class="select-div"
                 :class="{'placeholder': !modifiedUser.marriage, 'readonly': !!user.marriage&& !needUploadIdentity}">
            <span class="item-value"
                  @click="showMarriageSelect=!user.marriage || needUploadIdentity">
              {{ modifiedUser.marriage || $t('base_info_choose_marriage_state') }}
            </span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="info-item">
            <span class="item-desc">{{ $t('base_info_education') }}:</span>
            <div class="select-div"
                 :class="{'placeholder': !modifiedUser.education, 'readonly': !!user.education && !couldModify}">
            <span class="item-value" @click="showEducationSelect=!user.education || couldModify">
              {{ modifiedUser.education || $t('base_info_education_select_placeholder') }}
            </span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="info-item">
            <span class="item-desc">{{ $t('base_info_email') }}:</span>
            <el-form-item prop="email">
              <el-input class="item-value" v-model="modifiedUser.email" :disabled="!!user.email && !couldModify"
                        :class="{'readonly': !!user.email && !couldModify}"
                        :placeholder="$t('base_info_email_input_placeholder')"/>
            </el-form-item>
          </div>
          <div class="info-item">
            <span class="item-desc">Facebook link:</span>
            <el-input class="item-value" v-model="modifiedUser.facebook" :disabled="!!user.facebook && !couldModify"
                      :class="{'readonly': !!user.facebook && !couldModify }"
                      :placeholder="$t('common_input_placeholder')"/>
          </div>
          <div class="province-city">
            <div class="info-item">
              <span class="item-desc">{{ $t('common_province') }}:</span>
              <el-input class="item-value" v-model="province" :disabled="!!user.provinceCity && !couldModify"
                        :class="{'readonly': !!user.provinceCity && !couldModify}"
                        :placeholder="$t('common_input_placeholder')"/>
            </div>
            <div class="info-item">
              <span class="item-desc">{{ $t('common_city') }}:</span>
              <el-input class="item-value" v-model="city" :disabled="!!user.provinceCity && !couldModify"
                        :class="{'readonly': !!user.provinceCity && !couldModify}"
                        :placeholder="$t('common_input_placeholder')"/>
            </div>
          </div>
          <div class="info-item">
            <span class="item-desc">{{ $t('base_info_detail_address') }}:</span>
            <el-input class="item-value" v-model="modifiedUser.address" :disabled="!!user.address && !couldModify"
                      :class="{'readonly': !!user.address && !couldModify}"
                      :placeholder="$t('base_info_detail_address_input_placeholder')"/>
          </div>
        </div>
        <div class="user-info bankcard">
          <div class="title-div">
            <span class="title">{{ $t('common_bank_account') }}</span>
          </div>
          <div class="info-item">
            <span class="item-desc">{{ $t('common_bank') }}:</span>
            <el-input class="item-value" v-model="modifiedUser.bankName" :disabled="!!user.bankName&& !couldModify"
                      :class="{'readonly': !!user.bankName && !couldModify}" :placeholder="$t('common_bank_name')"/>
          </div>
          <div class="info-item">
            <span class="item-desc">{{ $t('common_bank_account') }}:</span>
            <el-input class="item-value" v-model="modifiedUser.cardNo" :disabled="!!user.cardNo&& !couldModify"
                      :class="{'readonly': !!user.cardNo&& !couldModify }" :placeholder="$t('common_bank_account')"
                      :type="showPassword? 'text':'password'" ref="password"
            >
              <img class="eye" slot="suffix" :src='showPassword? closeEyeIcon : openEyeIcon' @click="togglePassword">
            </el-input>
          </div>
        </div>
      </el-form>
    </div>
    <van-button class="submit-btn" type="info" block @click="goNext" :loading="loading" :disabled="disabled">
      {{ $t('common_next') }}
    </van-button>
    <van-popup v-model="showMarriageSelect" position="bottom">
      <van-picker show-toolbar
                  :columns="marriageList"
                  @cancel="showMarriageSelect=false"
                  @confirm="selectMarriage"
                  :confirm-button-text="$t('common_confirm')"
                  :cancel-button-text="$t('common_cancel')"/>
    </van-popup>
    <van-popup v-model="showBirthdaySelect" position="bottom" round>
      <van-datetime-picker type="date"
                           :title="$t('base_info_birthday_select_placeholder')"
                           v-model="currentDate"
                           @cancel="showBirthdaySelect=false"
                           @confirm="confirmBirthday"
                           :min-date="minDate"
                           :max-date="maxDate"
                           :confirm-button-text="$t('common_confirm')"
                           :cancel-button-text="$t('common_cancel')"/>
    </van-popup>
    <van-popup v-model="showProvinceCitySelect" position="bottom">
      <van-picker show-toolbar
                  :columns="provinceList"
                  @cancel="showProvinceCitySelect=false"
                  @confirm="selectProvince"
                  :confirm-button-text="$t('common_confirm')"
                  :cancel-button-text="$t('common_cancel')"/>
    </van-popup>
    <van-popup v-model="showEducationSelect" position="bottom">
      <van-picker show-toolbar
                  :columns="educationList"
                  @cancel="showEducationSelect=false"
                  @confirm="selectEducation"
                  :confirm-button-text="$t('common_confirm')"
                  :cancel-button-text="$t('common_cancel')"/>
    </van-popup>
  </div>
</template>
<script>
import store from "@/store";
import {userService} from "@/services";
import provinceList from '@/assets/province-city.json';
import titleImg from '../../assets/baseInfo-title.png';
import {formatDate} from "@/common/formatter";

export default {
  name: 'BaseInfoPage',
  data() {
    return {
      titleImg,
      modifiedUser: {},
      loading: false,
      showProvinceCitySelect: false,
      showEducationSelect: false,
      educationList: [],
      provinceList,
      showBankSelect: false,
      user: {},
      province: null,
      city: null,
      formRules: {
        // email: [
        //   {type: 'email', message: this.$t('error_email')},
        // ],
      },
      couldModify: false,
      showPassword: false,
      closeEyeIcon: require('@/assets/eye-close.png'),
      openEyeIcon: require('@/assets/eye-open.png'),
      currentDate: new Date(1990, 0, 1),
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      identityList: [{
        name: 'frontImage',
        desc: 'id_card_upload_front',
        url: require('@/assets/identity.png'),
        file: null
      }, {
        name: 'backImage',
        desc: 'id_card_upload_back',
        url: require('@/assets/identity-back.png'),
        file: null
      }, {
        name: 'handHoldImage',
        desc: 'id_card_upload_hold',
        url: require('@/assets/identity-handhold.png'),
        file: null
      }],
      holdFormData: new FormData(),
      showBirthdaySelect: false,
      needUploadIdentity: true,
      showMarriageSelect: false,
      marriageList: [],
    }
  },
  computed: {
    disabled() {
      return this.needUploadIdentity && this.identityList.filter(r => !r.file).length > 0
          || !this.modifiedUser.realName || !this.modifiedUser.identity || !this.modifiedUser.sex
          || !this.modifiedUser.birthday || !this.modifiedUser.marriage || !this.modifiedUser.education
          || !this.province || !this.city || !this.modifiedUser.email || !this.modifiedUser.facebook
          || !this.modifiedUser.address || !this.modifiedUser.bankName || !this.modifiedUser.cardNo
    },
  },
  async mounted() {
    this.modifiedUser = await store.getModifiedUser();
    this.user = await store.getUser();
    this.couldModify = !this.user.userQualificationView.bankCard;
    if (this.modifiedUser.provinceCity) {
      this.province = this.modifiedUser.provinceCity.split(",")[0];
      this.city = this.modifiedUser.provinceCity.split(",")[1];
    }
    // this.educationList = await store.getConfig('education')
    this.needUploadIdentity = this.user.userQualificationView.identity !== 1
        && this.user.userQualificationView.identity !== 2 && this.user.userQualificationView.identity !== 3
    if (!this.needUploadIdentity) {
      let identityUrlList = await userService.getIdentity();
      this.identityList[0].url = identityUrlList.frontImage;
      this.identityList[1].url = identityUrlList.backImage;
      this.identityList[2].url = identityUrlList.handHoldImage;
    }
    // this.marriageList = await store.getConfig('marriage')
  },
  activated() {
    this.educationList = [this.$t('junior_high_school_and_below'), this.$t('middle_school'), this.$t('high_school'),
      this.$t('graduate_and_above'), this.$t('none')]
    this.marriageList = [this.$t('single'), this.$t('married'), this.$t('divorced'), this.$t('widowed')]
  },
  methods: {
    uploadImg(data, item) {
      item.url = URL.createObjectURL(data.file);
      item.file = data.file;
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error(this.$t('img_size_tips'));
      }
      return isLt2M;
    },
    selectMarriage(value) {
      this.modifiedUser.marriage = value
      this.showMarriageSelect = false;
    },
    confirmBirthday(value) {
      this.modifiedUser.birthday = formatDate(value)
      this.showBirthdaySelect = false;
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    selectProvince(value) {
      this.modifiedUser.provinceCity = value.join(",")
      this.showProvinceCitySelect = false;
    },
    selectEducation(value) {
      this.modifiedUser.education = value
      this.showEducationSelect = false;
    },
    async goNext() {
      if (this.needUploadIdentity) {
        let formData = new FormData();
        for (let item of this.identityList) {
          formData.append(item.name, item.file);
        }
        userService.uploadIdentity(formData)
            .then(() => this.updateUser())
            .catch(() => this.loading = false);
      } else {
        this.updateUser();
      }
    },
    async updateUser() {
      await this.$refs.userForm.validate();
      let params = {
        realName: this.modifiedUser.realName,
        identity: this.modifiedUser.identity,
        sex: this.modifiedUser.sex,
        birthday: this.modifiedUser.birthday,
        marriage: this.modifiedUser.marriage,
        email: this.modifiedUser.email,
        facebook: this.modifiedUser.facebook,
        education: this.modifiedUser.education,
        provinceCity: this.province + ',' + this.city,
        address: this.modifiedUser.address,
      }
      userService.updateUser(params)
          .then(() => {
            this.updateBankcard()
          }).catch(() => this.loading = false);
    },
    async updateBankcard() {
      let params = {
        bankName: this.modifiedUser.bankName,
        cardNo: this.modifiedUser.cardNo,
      }
      userService.updateBankcard(params)
          .then(() => {
            this.loading = false;
            store.updateModifiedUser(this.modifiedUser)
            this.$router.push("/question")
          })
          .catch(() => this.loading = false);
    }
  }
}
</script>
<style lang="less" scoped>
.base-info {
  padding-top: 44px;
  min-height: calc(100% - 44px);
  display: flex;
  flex-direction: column;
  color: #222222;

  .header-title {
    padding: 15px 0;

    span {
      color: #222222;
      margin: auto;
      text-align: center;
      display: block;
      font-size: 28px;
      font-weight: 700;
    }
  }

  .page {
    flex-grow: 1;
    padding: 15px 15px calc(15% + 30px);

    .img-list {
      display: flex;
      flex-wrap: wrap;
      border-radius: 10px;
      background-color: @base-color;
    }
  }

  .identity-img-upload {
    width: calc(50% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    &:last-child {
      margin: auto;
      .identity-img {
        border-radius: 8px;
      }
    }

    .desc {
      flex: 1;
      display: flex;
      flex-direction: column;

      .index {
        font-size: 28px;
        font-weight: 700;
      }

      .img-desc {
        margin: 5px 0 10px;
        font-size: 14px;
      }
    }

    .uploader {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;

      .identity-img {
        width: 100%;
      }
    }
  }

  .user-info {
    margin-top: 15px;
    padding: 10px;
    border-radius: 10px;
    background-color: @base-color;
    .item-desc {
      margin: 10px 0;
      display: block;
      font-size: 14px;
    }

    .info-item {
      margin: 20px 0;

      .item-value {
        display: block;
        height: 45px;
        line-height: 45px;

        /deep/ input.el-input__inner {
          border: none;
          border-radius: 5px;
          height: 45px;
          line-height: 45px;
        }
      }

      span.item-value {
        padding-left: 15px;
        border-radius: 8px;
        font-size: 14px;
        background-color: #ffffff;
        color: #606266;
      }

      .placeholder {
        .item-value {
          color: #CCCCCC;
          font-weight: 300;
        }
      }
    }

    .province-city {
      display: flex;

      .info-item {
        flex: 1;
        margin-top: 0;
        margin-bottom: 0;

        .item-desc {
          margin-top: 0;
        }

        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }
      }
    }


    .title-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      text-align: center;

      .split-line {
        background-color: #CBC2C2;
        height: 1px;
        line-height: 1px;
        display: inline-block;
        flex: 1;
      }

      .title {
        padding: 0 10px;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        width: 100%;
      }
    }

    .radio {
      font-size: 14px;

      .van-radio {
        flex: 1;

        /deep/ .van-radio__icon {
          font-size: 14px;
          .van-icon {
            background-color: #ffffff;
          }
          &.van-radio__icon--checked {
            .van-icon {
              color: #1D49A7;
              border-color: #1D49A7
            }
          }
        }
        /deep/ .van-radio__label {
          color: #222222;
        }
      }
    }
  }
  .bankcard {
    background-color: @base-color;
  }
  .submit-btn {
    position: fixed;
    bottom: 20px;
    width: calc(100% - 40px);
    background: @button-color;
    border: none;
    height: 45px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 18px;
    margin: 0 20px;
  }


  .select-div {
    position: relative;
    display: flex;
    align-items: center;

    span {
      width: 100%;
    }

    i {
      position: absolute;
      color: #7972E6;
      right: 10px;
      font-size: 18px;
    }
  }

  .radio-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .item-desc {
      flex-grow: 1;
    }

    .item-value {
      justify-content: space-between;
      flex-grow: 1;
    }

    .van-radio {
      margin-right: 0;
    }
  }

  .readonly {

    /deep/ input.el-input__inner {
      border-color: #E4E7ED !important;
    }

    span.item-value {
      border-color: #E4E7ED !important;
      background-color: #F5F7FA !important;
      color: #C0C4CC !important;
    }

    i {
      display: none;
    }
  }

  /deep/ .el-input__suffix-inner {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .eye {
    height: 22px;
    display: block;
  }
}
</style>