<template>
  <div class="page">
    <header-bar />
    <div class="title">
      <img :src="partnerImg"/>
      <div class="title-desc">
        <span>Partner</span>
      </div>
    </div>
    <div class="partner-info">
      <div class="info-item" v-for="(item, index) of partnerList" :key="index">
        <img :src="item.icon">
        <span class="item-desc">{{item.name}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import partnerImg from '../assets/partner.png';

export default {
  name: "PartnerPage",
  data() {
    return {
      partnerImg,
      partnerList: [
        {
          icon: require("@/assets/patner/日本銀行ロゴ.png"),
          name: "日本銀行"
        },
        {
          icon: require("@/assets/patner/預金保険機構.svg"),
          name: "預金保険機構"
        },
        {
          icon: require("@/assets/patner/金融情報分析センター.png"),
          name: "金融情報分析センター"
        },
        {
          icon: require("@/assets/patner/金融庁.png"),
          name: "金融庁"
        },{
          icon: require("@/assets/patner/日本貸金業協会.svg"),
          name: "日本貸金業協会"
        },
        {
          icon: require("@/assets/patner/経済産業省ロゴ.png"),
          name: "経済産業省"
        },
        {
          icon: require("@/assets/patner/三菱银行.png"),
          name: "三菱UFJ銀行"
        },
        {
          icon: require("@/assets/patner/三井住友银行.png"),
          name: "三井住友銀行"
        },
        {
          icon: require("@/assets/patner/みずほ銀行.png"),
          name: "みずほ銀行"
        },
        {
          icon: require("@/assets/patner/りそな銀行.png"),
          name: "りそな銀行"
        },
        {
          icon: require("@/assets/patner/じぶん銀行.png"),
          name: "じぶん銀行"
        },
        {
          icon: require("@/assets/patner/paypay.png"),
          name: "PayPay"
        },
      ]
    }
  },
}
</script>
<style lang="less" scoped>
.page {
  background-color: @base-color;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    padding: 10% 0 5%;
    display: flex;
    flex-direction: row;
    img {
      width: 45%;
    }
    .title-desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      span {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 1.5em;
      }
    }
  }

  .partner-info {
    flex-grow: 1;
    background-color: #ffffff;
    border-radius: 20px 20px 0 0;
    padding: 0 20px;

    .info-item {
      margin: 20px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #F4F4F4;
      padding: 3% 5%;
      border-radius: 8px;
      img {
        width: 30%;
        margin-right: 20px;
      }
      .item-desc {
        margin: 10px 0;
        font-size: 14px
      }
    }
  }
}
</style>
