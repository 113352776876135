<template>
  <div class="img-div">
    <template v-for="(item, index) of imgList">
      <img class="img-item" :src="item.img" :key="index"/>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ImageList',
  data() {
    return {
      imgList: [
        {
          img: require("@/assets/patner/日本銀行ロゴ.png"),
        },
        {
          img: require("@/assets/patner/預金保険機構.svg"),
        },
        {
          img: require("@/assets/patner/日本貸金業協会.svg"),
        },
        {
          img: require("@/assets/patner/経済産業省ロゴ.png"),
        },
        {
          img: require("@/assets/patner/金融庁.png"),
        },
        {
          img: require("@/assets/patner/三菱银行.png"),
        },
        {
          img: require("@/assets/patner/三井住友银行.png"),
        },
        {
          img: require("@/assets/patner/みずほ銀行.png"),
        },
        {
          img: require("@/assets/patner/りそな銀行.png"),
        },
        {
          img: require("@/assets/patner/じぶん銀行.png"),
        },
        {
          img: require("@/assets/patner/paypay.png"),
        },
        {
          img: require("@/assets/patner/金融情報分析センター.png"),
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.img-div {
  display: flex;
  margin: 0 20px 0;
  justify-content: space-between;
  overflow: scroll;

  .img-item {
    width: 25%;
    margin: 0 3px;
  }
}
</style>
