<template>
  <div class="page">
    <div class="lock-div">
      <img class="lock" :src="lockImg"/>
      <div class="info">
        <span class="title">THE SECURITY SYSTEM</span>
        <div class="split-line"></div>
        <div class="detail">
          <div class="detail-item" v-for="(item, index) of infoItem" :key="index">
            <span class="detail-desc">{{ item.label }}:</span>
            <span class="detail-value">{{ item.value }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="div2">
      <span class="title2">LOAN STATUS:LOCKED</span>
      <div class="desc">Please contact to finance unlock your loan application!</div>
      <div class="input-div">
<!--        <el-input class="code-input" type="text" v-model="unlockCode" :placeholder="$t('common_input_placeholder')"/>-->
<!--        <van-button class="code-btn" :loading="loading" :disabled="!unlockCode" :class="{'disabled': !unlockCode}"-->
<!--                    size=small @click="unlock">-->
<!--          OK-->
<!--        </van-button>-->
      </div>
    </div>
  </div>
</template>
<script>
import lockTipsImg from '../assets/lock-tips.png';
import lockImg from '../assets/lock.png';
import systemImg from '../assets/system.png';

import {userService} from "@/services";
import store from '@/store';

export default {
  name: "LockPage",
  data() {
    return {
      lockTipsImg,
      lockImg,
      systemImg,
      unlockCode: '',
      loading: false,
      info: {},
    }
  },
  computed: {
    infoItem() {
      return [
        {
          "label": this.$t("common_name"),
          "value": this.info.realName
        },
        {
          "label": this.$t('common_phone'),
          "value": this.info.mobile
        },
        {
          "label": this.$t("common_id_card"),
          "value": this.info.identity
        },
        {
          "label": this.$t('base_info_detail_address'),
          "value": this.info.address
        },
        {
          "label": this.$t('common_order_id'),
          "value": this.info.lastOrderId
        },
      ]
    },
  },
  async activated() {
    this.info = await store.getUser(true);
  },
  methods: {
    async unlock() {
      let params = {
        unlockCode: this.unlockCode,
      }
      if (this.unlockCode) {
        this.loading = true;
        userService.unlock(params)
            .then((token) => {
              this.loading = false;
              store.setUnlockToken(token);
              this.$router.push('/');
            })
            .catch(() => this.loading = false);
      }
    },
  }
}
</script>
<style lang="less" scoped>
.page {
  background-color: #353D48;
  width: 100%;
  min-height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .title {
    display: block;
    margin-bottom: 15px;
  }

  .lock-div {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    align-items: center;

    .lock {
      width: 55%;
    }
    .title {
      width: 100%;
      color: #E20800;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
    }
  }
  .title2 {
    margin-bottom: 15px;
    font-size: 16px;
    color: #E20800;
    font-weight: 700;
    text-align: center;
  }
  .split-line {
    height: 1px;
    margin: 0 auto 25px;
    border-bottom: 1px dashed #002596;
    width: calc(100% - 20px);
  }
  .desc {
    font-size: 12px;
    margin-bottom: 10px;
    color: #333333;
    text-align: center;
    font-weight: 700;
  }

  .info {
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fill-available;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: @base-color;

    .avatar {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;

      img {
        width: 60px;
        height: 60px;
        border-radius: 100%;
      }

      span {
        margin-left: 20px;
        color: #ffffff;
        font-size: 18px;
        font-weight: 700;
      }
    }

    .detail {
      font-size: 14px;
      color: #116287;

      .detail-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        .detail-desc {
        }

        .detail-value {
          max-width: 60%;
          opacity: 0.8;
        }
      }
    }
  }

  .div2 {
    background: #FE9E14;
    border-radius: 6px;
    margin: 10px 20px 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .input-div {
    margin: 0 35px;
    display: flex;
    flex-direction: column;

    .code-input {
      /deep/ input {
        height: 45px;
        border-radius: 8px;
      }

      margin-bottom: 20px;
    }

    .code-btn {
      width: 100%;
      height: 45px;
      border-radius: 8px;
      background: @button-color;
      color: #FFFFFF;
      font-size: 16px;
      border: none;

      &.disabled {
        opacity: 0.6;
      }
    }
  }
}
</style>
