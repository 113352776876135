import { render, staticRenderFns } from "./InformationQuestion.vue?vue&type=template&id=92f0162e&scoped=true"
import script from "./InformationQuestion.vue?vue&type=script&lang=js"
export * from "./InformationQuestion.vue?vue&type=script&lang=js"
import style0 from "./InformationQuestion.vue?vue&type=style&index=0&id=92f0162e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92f0162e",
  null
  
)

export default component.exports