<template>
  <el-select
    v-model="language"
    @change="changeLanguage"
    style="width: 80px"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>

<script>
import { languageService } from '@/services';

export default {
  name: 'LanguageSwitch',
  data() {
    return {
      language: process.env.DEPLOY_CONFIGS.lang,
      options: [
        {
          value: 'ja',
          label: 'ja',
        },
        {
          value: 'en',
          label: 'en',
        }
      ],
    };
  },
  created() {
    languageService.checkout();
    this.language = this.$i18n.locale;
  },
  methods: {
    changeLanguage(value) {
      languageService.switch(value);
    },
  },
};
</script>
